import React, { useState } from 'react';
import { Box, Button, Select } from '@chakra-ui/react';


interface MonthYearPickerParams {
    onDateChange: (month:number, year:number)=>void
}

const MonthYearPicker = ({  onDateChange }: MonthYearPickerParams) => {
    const [selectedDate, setSelectedDate] = useState(new Date());

    const handlePreviousDay = () => {
        const previousDate = new Date(selectedDate);
        previousDate.setDate(previousDate.getDate() - 1);
        setSelectedDate(previousDate)
        onDateChange(previousDate.getMonth() + 1, previousDate.getFullYear()); // Call the callback function with the updated date
    };

    const handleNextDay = () => {
        const nextDate = new Date(selectedDate);
        nextDate.setDate(nextDate.getDate() + 1);
        setSelectedDate(nextDate)
        onDateChange(nextDate.getMonth() + 1, nextDate.getFullYear()); // Call the callback function with the updated date
    };

    const formatDate = (date:Date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${month < 10 ? '0' + month : month}-${year}`;
    };

    const handleChangeMonth = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const month = parseInt(event.target.value);
        const newDate = new Date(selectedDate);
        newDate.setMonth(month - 1); // Month in JavaScript Date object is 0-indexed
        setSelectedDate(newDate);
        onDateChange(newDate.getMonth() + 1, newDate.getFullYear()); // Call the callback function with the updated date
      };
    
      const handleChangeYear = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const year = parseInt(event.target.value);
        const newDate = new Date(selectedDate);
        newDate.setFullYear(year);
        setSelectedDate(newDate);
        onDateChange(newDate.getMonth() + 1, newDate.getFullYear()); };

    return (
        <Box display="flex" alignItems="center">
          <Button onClick={handlePreviousDay}>&lt;</Button>
          <Select mx={2} value={selectedDate.getMonth() + 1} onChange={handleChangeMonth}>
            {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
              <option key={month} value={month}>
                {month}
              </option>
            ))}
          </Select>
          /
          <Box mx={2} minWidth="120px">
          <Select  value={selectedDate.getFullYear()} onChange={handleChangeYear}>
            {Array.from({ length: 10 }, (_, i) => selectedDate.getFullYear() - 5 + i).map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </Select>
          </Box>
          <Button onClick={handleNextDay}>&gt;</Button>
        </Box>
      );
};

export default MonthYearPicker;
