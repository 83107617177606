
export const validateDate =(date:string | Date, def?:undefined)=> {
    let date2:Date
    if (date instanceof Date) {
        date2 = date
    } else {
        try {
        date2 = new Date(date)
        } catch {
            if (def) {
                return def
            }
            throw new Error('invalid date format')
        }
    }
    return date2
}

export function getFirstDayString(date: string | Date) {
    const date2 = validateDate(date)
    return `01-${date2.getMonth() + 1}-${date2.getFullYear()}`
}

export function getFirstDayStringFromMonthAndYear(month:number, year:number) {
    return `01-${month.toString().padStart(2,'0')}-${year}`
}

export function getLastDayString(date: string | Date) {
    const date2 = validateDate(date)
    return `31-${date2.getMonth() + 1}-${date2.getFullYear()}`
}

export function getLastDayStringFromMonthAndYear(month:number, year:number) {
    return `31-${month.toString().padStart(2,'0')}-${year}`
}


export function getYMD(date: string | Date) {
    const date2 = validateDate(date);

    const year = date2.getFullYear();
    const month = (date2.getMonth() + 1).toString().padStart(2, '0');
    const day = date2.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
}


