import React, { useEffect, useState } from "react";
import BarChart from "./BarChart";
import { Link } from "react-router-dom";
import { Customer } from "../models/Customer";
import { getCustomers, getCustomersCount } from "../services/CustomerService";
import { ChevronDownIcon, RepeatIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

import {
    ListItem,
    UnorderedList,
    Box,
    Heading,
    Card,
    Flex,
    Button,
    useBreakpointValue,
} from '@chakra-ui/react'
import MainMenu from "./MainMenu";
import { CountByDate } from "../models/CountByDate";

const Dashboard: React.FC = () => {
    const navigate = useNavigate()
    const isMobile = useBreakpointValue({ base: true, md: false });

    const [customers, setCustomers] = useState<Customer[]>([]);

    const [customersCounts, setCustomerCounts] = useState<CountByDate[]>([])
    const [pendingCustomers, setPendingCustomers] = useState<Customer[]>([]);

    useEffect(() => {
        fetchCustomers();
    }, []);

    async function fetchCustomers() {
        try {
            const counts = await getCustomersCount({})
            // console.log(counts);

            setCustomerCounts(counts)
        } catch (error) {
            console.error("Failed to fetch customers:", error);
        }

        try {
            const customersData = await getCustomers({ status: 0 });
            setPendingCustomers(customersData);
        } catch (error) {
            console.error("Failed to fetch pending customers:", error);
        }

        try {
            const customersData = await getCustomers({ sortBy: 'id', sortOrder: 'desc' });
            setCustomers(customersData);
        } catch (error) {
            console.error("Failed to fetch pending customers:", error);
        }

    }



    const salesData = customersCounts.map((item) => {

        const month = new Date(item.date.replace('00', '01')).toLocaleString("en-US", { month: "long" });
        return { month, value: item.count };
    });


    const labels = salesData.map((data) => data.month);
    const values = salesData.map((data) => data.value);

    const latestItems: Customer[] = customers.slice(0, 10)

    return (
        <Box padding={6} alignItems={"center"}>

            <Flex justifyContent="space-between" alignItems="center">
                <MainMenu current="dashboard" />
                <Heading as="h2" size="xl" color={"white"}>Welcome</Heading>
            </Flex>

            <Flex gap={4} flexDir={"column"} mx={"auto"} mt={20} alignItems={"center"} w={"100vw"} maxW={isMobile ? "100%" : "800px"}>
                <Card p={4} w={"100%"}>
                    <Heading as="h3" size="lg">Data Stats</Heading>
                    <Box>
                        {customersCounts.length > 0 ? (
                            <BarChart data={values} labels={labels} />
                        ) : (
                            <p>no data...</p>
                        )}
                    </Box>
                </Card>
                <Card p={4} w={"100%"}>
                    <Heading as="h3" mb={3} size="lg">Pending Lab Data</Heading>
                    {pendingCustomers.length > 0 ? (
                        <UnorderedList spacing={2}>
                            {pendingCustomers.map((item: Customer) => (
                                <ListItem color={"#333"} key={item.id} display="flex" alignItems="center" gap={2}>
                                    <RepeatIcon color="green.500" />
                                    {item.name}
                                </ListItem>
                            ))}
                        </UnorderedList>
                    ) : (
                        <p>no data</p>
                    )}
                </Card>
                <Card p={4} w={"100%"}>
                    <Heading as="h3" size="lg" mb={3}>Latest Data</Heading>
                    {customers.length > 0 ? (
                        <>
                            <UnorderedList mb={3}>
                                {latestItems.slice(0, 10).map((item: any) => (
                                    <ListItem color={"#333"} key={item.id}>{item.name}</ListItem>
                                ))}
                            </UnorderedList>
                            <Flex justifyContent="flex-end" mt={2}>
                                <Button as={Link} to="/data" colorScheme="facebook">View All Data</Button>
                            </Flex>
                        </>
                    ) : (
                        <p>Loading data...</p>
                    )}
                </Card>
            </Flex>
        </Box>
    );
}

export default Dashboard;
