import {
    Box, Flex, Text, Image, Input,
    Button,
    Select,
} from "@chakra-ui/react";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";


const Analysis: React.FC = () => {

    interface FingerData {
        ulnar: number;
        radial: number;
        type: number;
    }

    interface CombinedData {
        file: string;
        finger: FingerData;
    }

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const [selectedFile, setSelectedFile] = useState<string | null>(null);
    const [selectedFingerType, setSelectedFingerType] = useState<number>(1);
    const [ulnarValue, setUlnarValue] = useState(0);
    const [radialValue, setRadialValue] = useState(0)
    const [selectedFingerIndex, setSelectedFingerIndex] = useState<string>('l1')


    const [combinedData, setCombinedData] = useState<Record<string, CombinedData>>({
        l1: { file: '', finger: { ulnar: 0, radial: 0, type: 1 } },
        l2: { file: '', finger: { ulnar: 0, radial: 0, type: 1 } },
        l3: { file: '', finger: { ulnar: 0, radial: 0, type: 1 } },
        l4: { file: '', finger: { ulnar: 0, radial: 0, type: 1 } },
        l5: { file: '', finger: { ulnar: 0, radial: 0, type: 1 } },
        r1: { file: '', finger: { ulnar: 0, radial: 0, type: 1 } },
        r2: { file: '', finger: { ulnar: 0, radial: 0, type: 1 } },
        r3: { file: '', finger: { ulnar: 0, radial: 0, type: 1 } },
        r4: { file: '', finger: { ulnar: 0, radial: 0, type: 1 } },
        r5: { file: '', finger: { ulnar: 0, radial: 0, type: 1 } },
    });

    const radialValues = Object.keys(combinedData)
        .map(key => combinedData[key].finger.radial)
        .join(',');

    const ulnarValues = Object.keys(combinedData)
        .map(key => combinedData[key].finger.ulnar)
        .join(',');

    const fingerTypes = Object.keys(combinedData)
        .map(key => combinedData[key].finger.type)
        .join(',');

    useEffect(() => {
        console.log('data: ', combinedData);

    }, [combinedData])

    useEffect(() => {
        console.log('woi');

        updateFileValue(selectedFingerIndex, selectedFile!)
    }, [selectedFile])

    const updateFileValue = (fingerKey: string, newFileValue: string) => {
        setCombinedData((prevData) => ({
            ...prevData,
            [fingerKey]: { ...prevData[fingerKey], file: newFileValue },
        }));
    };

    const updateCombinedData = (
        fingerKey: string,
        fileValue: string,
        fingerValue: FingerData
    ) => {
        setCombinedData((prevData) => ({
            ...prevData,
            [fingerKey]: { file: fileValue, finger: fingerValue },
        }));
    };

    const handleButtonNext = () => {
        updateCombinedData(selectedFingerIndex, selectedFile!, { ulnar: ulnarValue, radial: radialValue, type: selectedFingerType! })
        // to clear image 
        setSelectedFile('')
        

    }


    const handleClick = () => {
        fileInputRef.current?.click();
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const fileReader = new FileReader();

            fileReader.onloadend = () => {
                const imageDataUrl = fileReader.result as string;
                setSelectedFile(imageDataUrl);
            };

            fileReader.readAsDataURL(file);
        } else {
            setSelectedFile(null);
        }

    };

    const handleSelectFileChange = (event: React.ChangeEvent<HTMLSelectElement>) => {

        const selectedFingerIndex = event.target.value;
        setSelectedFingerIndex((selectedFingerIndex));


        Object.entries(combinedData).forEach(([fingerIndex, value]) => {
            if (fingerIndex == selectedFingerIndex) {
                setSelectedFile(value.file)
            }
        })
    };

    const handleSelectFingerTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = parseInt(event.target.value);
        setSelectedFingerType(selectedValue)
    };

    const handleUlnarInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value)
        if (value >= 0) {
            setUlnarValue(value);
        } else {
            setUlnarValue(0)
        }
    };


    const handleRadialInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value)
        if (value >= -0) {
            setRadialValue(value);
        } else {
            setUlnarValue(0)
        }

    };


    return (
        <Flex alignItems={'center'} flexDir={'column'}>
            <Box bg={'white'} width={'90%'} py={20} mt={8} position={'relative'} >
                <Image
                    src={selectedFile || "path/to/your/image.jpg"}
                    alt="Interactive Image"
                    onClick={handleClick}
                    cursor="pointer"
                    borderRadius="md" // Adjust as needed
                    height={'100%'}
                    width={'100%'}
                    objectFit={'contain'}
                />
                <Box
                    position={'absolute'}
                    top={0}
                    left={0}
                    width="100%"
                    height="100%"
                    cursor="pointer"
                    onClick={handleClick}
                    zIndex={1}
                    opacity={0} // Make the overlay transparent
                />
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                />

            </Box>

            <Text color={'white'} mt={4}> Finger</Text>
            <Box bg={'white'} w={'85%'} mt={2}>

                <Select placeholder="" onChange={handleSelectFileChange} value={selectedFingerIndex !== null ? String(selectedFingerIndex) : undefined}
                >
                    <option value='l1'>l1</option>
                    <option value='l2'>l2</option>
                    <option value='l3'>l3</option>
                    <option value='l4'>l4</option>
                    <option value='l5'>l5</option>
                    <option value='r1'>r1</option>
                    <option value='r2'>r2</option>
                    <option value='r3'>r3</option>
                    <option value='r4'>r4</option>
                    <option value='r5'>r5</option>


                </Select>
            </Box>

            <Flex flexDir={'row'} mx={8} mt={4} gap={4}>
                <Flex flexDirection={'column'} alignItems={'center'}>
                    <Text color={'white'} mb={2}> Ulnar </Text>
                    <Input onChange={handleUlnarInputChange} value={ulnarValue} bg={'white'} placeholder="Ulnar" py={6} />
                </Flex>
                <Flex flexDirection={'column'} alignItems={'center'} >
                    <Text color={'white'} mb={2}> Radial </Text>
                    <Input onChange={handleRadialInputChange} value={radialValue} bg={'white'} placeholder="Radial" py={6} />

                </Flex>

            </Flex>

            <Flex textAlign={'center'} flexDir={'column'} w={'90%'} align={'center'} mt={4}>
                <Text color={'white'}> Finger Type </Text>

                <Box bg={'white'} w={'85%'} mt={2}>
                    <Select placeholder="" onChange={handleSelectFingerTypeChange} value={selectedFingerType || undefined}
                    >
                        <option value='2'>Loop</option>
                        <option value='5'>RLoop</option>
                        <option value='4'>Double Loop</option>
                        <option value='3'>Whorl</option>
                        <option value='1'>Arch</option>



                    </Select>
                </Box>
            </Flex>


            <Box mt={8}>
                <Button px={16} py={6} onClick={handleButtonNext}> Save</Button>

            </Box>

            <Flex w={'100%'} py={8} mt={8} bgColor={'white'} flexDirection={'column'} pl={6} gap={2}>

                <Text fontSize={'lg'}> Ulnar: {ulnarValues}</Text>
                <Text fontSize={'lg'}> Radial: {radialValues}</Text>
                <Text fontSize={'lg'}> FingerTypes: {fingerTypes}</Text>


            </Flex>


        </Flex>



    );
};

export default Analysis;
