import {
    Box, Flex, Heading, Text, Image, Input, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Spinner,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import config from "../config";
import { User } from "../models/User";
import { Customer } from "../models/Customer";
import { Link } from "react-router-dom";

const CurrentEvent: React.FC = () => {
    let [customers, setCustomers] = useState<Customer[]>([])
    const [selectedCustomer, setSelectedCustomer] = useState<Customer>()
    const [dd, setDD] = useState('')
    const [mm, setMM] = useState('')
    const [yyyy, setYYYY] = useState('')
    const [downloadLink, setDownloadLink] = useState<string | undefined>(undefined)
    const downloadButton = useRef<HTMLButtonElement | null>(null)
    const [processingPDF, setProcessingPDF] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

    useEffect(() => {
        if (downloadLink) {
            const anchorElement = document.createElement('a');

            // Set the href attribute to the file URL
            anchorElement.href = downloadLink;

            // Set the download attribute to specify the filename to be saved as
            anchorElement.download = 'filename.ext'; // Replace 'filename.ext' with your desired filename

            // Trigger a click event on the anchor element
            anchorElement.click();

        }
    }, [downloadLink])

    function addZero(number: number) {
        return number < 10 ? "0" + number : number.toString();
    }
    const downloadPdf = async () => {
        if (isNaN(+dd) || isNaN(+mm) || isNaN(+yyyy)) return


        try {

            setProcessingPDF(true)

            const response = await axios.post<{ downloadLink: string }>(`${config.apiUrl}/events/current/customers/${selectedCustomer!.id}/pdf`, {
                birthdate: `${yyyy}-${addZero(+mm)}-${addZero(+dd)}`
            }, { withCredentials: false });
            const { downloadLink } = response.data;
            setDownloadLink(downloadLink)
        } catch (error) {
            // Handle error appropriately (e.g., logging, error handling)
            console.log(error);

            setErrorMessage('error')
        } finally {
            setProcessingPDF(false)
        }
    }

    const loadCustomers = async () => {
        try {
            const response = await axios.get<Customer[]>(`${config.apiUrl}/events/current/customers`, {
                withCredentials: false
            });

            console.log(response.data.length);


            const sortedCustomers = response.data.slice(); // Create a shallow copy to avoid modifying the original array
            sortedCustomers.sort((a, b) => a.name.localeCompare(b.name)); // Sort by the 'name' property in alphabetical order

            setCustomers(sortedCustomers);
            return
        } catch (error) {
            alert('event is closed')
        }
    }

    useEffect(() => {
        loadCustomers()
    }, [])

    return (
        <Flex flexDir={'column'} padding={6}>
            <Image src="https://talentspectrum.id/img/talent-spectrum-logo-light.svg" boxSize={"100px"} objectFit={"contain"} mt={"0"} mx={'auto'} />

            <Flex flexDir={'row'} alignItems={'center'} gap={10} justifyContent={'center'}>
                <Heading size={'md'} color={'white'}>    EDU ALL 2024</Heading>
            </Flex>
            <Flex bgColor={'white'} height={'100%'} width={'100%'} px={6} py={6} flexDirection={'column'} mt={16} gap={8} borderRadius={10} mx={'auto'}>
                {customers.map((customer, index) => (
                    <Text
                        border={'solid black 0.5px'}
                        padding={3}
                        fontFamily={'sans-serif'}
                        // fontWeight={'bold'}
                        fontSize={'xl'}
                        key={index}
                        as="span" // Render as a <span> to make it clickable
                        onClick={() => { setSelectedCustomer(customer) }} // Handle the click event
                        cursor="pointer" // Change the cursor to a pointer on hover
                    >
                        {index + 1}. {customer.name}
                    </Text>
                ))}
            </Flex>

            <Modal isOpen={selectedCustomer != undefined} onClose={() => {
                setSelectedCustomer(undefined)
                setDownloadLink(undefined)
            }}>

                <ModalOverlay />
                <ModalContent>
                    <ModalHeader> {downloadLink ? 'Starting your download' : ''}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>

                        {

                            downloadLink ? processingPDF ? <Spinner></Spinner> : <Button px={6} fontSize={14} onClick={downloadPdf}>  Download Again </Button>
                                :
                                (
                                    <Flex justifyContent={'center'} alignItems={'center'} w={'100%'} flexDir={'column'} gap={6}>

                                        <Flex flexDir={'row'} gap={2}>
                                            <Input type="number" flex={1} placeholder="DD" value={dd} onChange={(e) => setDD(e.target.value)} />
                                            <Input type="number" flex={1} placeholder="MM" value={mm} onChange={(e) => setMM(e.target.value)} />
                                            <Input type="number" flex={2} placeholder="YYYY" value={yyyy} onChange={(e) => setYYYY(e.target.value)} />
                                        </Flex>
                                        {
                                            errorMessage ? <Text> Invalid birthdate</Text> : null
                                        }

                                        {
                                            processingPDF ? <Spinner></Spinner> : <Button px={6} fontSize={14} onClick={downloadPdf}>  Download </Button>
                                        }

                                    </Flex>

                                )
                        }

                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Flex >




    );
};

export default CurrentEvent;
