import { Box, Flex, Heading, Text, Image } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import ReportDownloadForm from "./ReportDownloadForm";

const Home: React.FC = () => {
    return (
        <Flex alignItems={"center"} flexDirection={"column"}>
            <Image src="ts_logo.png" boxSize={"150px"} objectFit={"contain"} mt={"100"} />
            <Heading color={"white"} size={"2xl"}>Selamat Datang</Heading>
            <Text color={"white"} fontSize={"xl"} maxW={"480px"} mt={4} textAlign={"center"}>Silahkan ini form dibawah ini untuk mengunduh report Fingerprint Analysis anda.</Text>
            <Flex backgroundColor={"white"} borderRadius={20} margin={"auto"} mt={"8"} minW={650} minH={600} alignItems={"center"}>
                <ReportDownloadForm></ReportDownloadForm>
            </Flex>

            <Box height={200}></Box>

            <Flex gap={4}>
                <Text as={Link} to={"https://talentspectrum.id"} color={"white"} right={2} bottom={2}>talentspectrum.id</Text>
                <Text color={"white"}>|</Text>
                <Text as={Link} to={"/login"} color={"white"} right={2} bottom={2}>member</Text>


            </Flex>
        </Flex >
    );
};

export default Home;
