import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react"
import { Customer } from "../models/Customer"
import { useState } from "react"
import { getCustomerPDF, postCustomerPDF } from "../services/CustomerService"
import { Link } from "react-router-dom"


interface CustomerPDFDownloaBoxProps {
    customer: Customer
}


const CustomerPDFDownloaBox: React.FC<CustomerPDFDownloaBoxProps> = (props: CustomerPDFDownloaBoxProps) => {

    const [message, setMessage] = useState("")
    const [isLoading, setLoading] = useState(false)
    const [downloadLink, setDownloadLink] = useState<string>()
    const { customer } = props


    function handleDownload() {
        setMessage("your download is being processed")
        setLoading(true)

        async function checkStatus(taskId: string): Promise<string | undefined> {
            const MAX_LOOP = 10;
            const DELAY_MS = 2000; // 1000 milliseconds = 1 second

            for (let i = 0; i < MAX_LOOP; i++) {
                const { status, downloadLink } = await getCustomerPDF(customer.id, taskId);
                if (status === 'DONE') {
                    return downloadLink;
                }
                await sleep(DELAY_MS); // Add a delay of DELAY_MS milliseconds before the next iteration
            }

            return undefined
        }

        function sleep(ms: number) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }

        postCustomerPDF(customer.id).then((r: { id: string }) => {
            setMessage("generating download link")

            checkStatus(r.id).then((downloadLink) => {
                if (downloadLink) {
                    setMessage("your download is ready")
                    setLoading(false)
                    setDownloadLink(downloadLink)
                } else {
                    setMessage('failed to genereate link')
                }
            })


        }).catch(error => {
            const errorMessage = error instanceof Error ? error.message : 'unknown error'
            setMessage("error: " + errorMessage)
        })

    }


    return (
        <Flex alignItems={"center"} justifyContent={"center"} padding={10}>



            {isLoading ? (
                <Flex alignItems={"center"} flexDir={"column"}>
                    <Spinner />
                    <Text mt={4}>{message}</Text>

                </Flex>
            ) : (
                downloadLink ? <Button as={Link} to={downloadLink}>click to start download</Button> : <Button onClick={handleDownload}>generate pdf</Button>

            )}


        </Flex>
    )

}

export default CustomerPDFDownloaBox
