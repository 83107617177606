import React, { useState } from 'react';
import { Box, Button, Select } from '@chakra-ui/react';

interface DatePickerParams {
  onDateChange: (date: Date) => void;
  isDisabled?: boolean
}

const DatePicker = ({ onDateChange, isDisabled = false }: DatePickerParams) => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handlePreviousDay = () => {
    const previousDate = new Date(selectedDate);
    previousDate.setDate(previousDate.getDate() - 1);
    setSelectedDate(previousDate);
    onDateChange(previousDate); // Call the callback function with the updated date
  };

  const handleNextDay = () => {
    const nextDate = new Date(selectedDate);
    nextDate.setDate(nextDate.getDate() + 1);
    setSelectedDate(nextDate);
    onDateChange(nextDate); // Call the callback function with the updated date
  };

  const handleChangeDay = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const day = parseInt(event.target.value);
    const newDate = new Date(selectedDate);
    newDate.setDate(day);
    setSelectedDate(newDate);
    onDateChange(newDate); // Call the callback function with the updated date
  };

  const handleChangeMonth = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const month = parseInt(event.target.value);
    const newDate = new Date(selectedDate);
    newDate.setMonth(month - 1); // Month in JavaScript Date object is 0-indexed
    setSelectedDate(newDate);
    onDateChange(newDate); // Call the callback function with the updated date
  };

  const handleChangeYear = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const year = parseInt(event.target.value);
    const newDate = new Date(selectedDate);
    newDate.setFullYear(year);
    setSelectedDate(newDate);
    onDateChange(newDate); // Call the callback function with the updated date
  };

  return (
    <Box display="flex" alignItems="center">
      <Button mr={2} isDisabled={isDisabled} onClick={handlePreviousDay}>&lt;</Button>
      <Select mx={1} value={selectedDate.getDate()} onChange={handleChangeDay}>
        {Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
          <option key={day} value={day}>
            {day}
          </option>
        ))}
      </Select>
      /
      <Select mx={1} value={selectedDate.getMonth() + 1} onChange={handleChangeMonth}>
        {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
          <option key={month} value={month}>
            {month}
          </option>
        ))}
      </Select>
      /
      <Box mx={1} minWidth="88px">
        <Select value={selectedDate.getFullYear()} onChange={handleChangeYear}>
          {Array.from({ length: 10 }, (_, i) => selectedDate.getFullYear() - 5 + i).map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </Select>
      </Box>
      <Button ml={2} isDisabled={isDisabled} onClick={handleNextDay}>&gt;</Button>
    </Box>
  );
};

export default DatePicker;
