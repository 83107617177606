import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Box,
  Heading,
  Text,
  Flex,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import config from "../config";

interface LoginFormProps {
  onLoggedIn: () => void
}

const LoginForm = ({ onLoggedIn }: LoginFormProps) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate()


  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError("");
    setIsLoading(true);

    try {
      const response = await fetch(`${config.apiUrl}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: email,
          password,
        }),
        credentials: "include"
      });

      if (!response.ok) {
        throw new Error("Invalid username or password");
      }

      const { accessToken } = await response.json();
      // console.log("Access Token:", accessToken);

      onLoggedIn()
    } catch (error: unknown) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("An unknown error occurred.");
      }
    }

    setIsLoading(false);
  };

  return (
    <Box pt={0}>
      <Box>.</Box>
      <Box p={4} borderRadius="lg" backgroundColor={"white"} maxW={700} mx={"auto"} mt={100}>
        <Heading as="h2" size="lg" mb={4}>
          Login
        </Heading>
        {error && <Text color="red">{error}</Text>}
        <Box as="form" onSubmit={handleSubmit} mt={10}>
          <FormControl>
            <FormLabel htmlFor="email">Email:</FormLabel>
            <Input
              type="text"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel htmlFor="password">Password:</FormLabel>
            <Input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </FormControl>
          <Flex justifyContent={"flex-end"} mt={20}>
            <Button
              type="submit"
              disabled={isLoading}
              isLoading={isLoading}
              loadingText="Logging in..."
              mt={4}
              width={150}
            >
              Login
            </Button>
          </Flex>
        </Box>
      </Box>
    </Box >
  );
};

export default LoginForm;
