import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom"; // Import useSearchParams from react-router-dom
import CustomerList from "./CustomerList";
import { Customer } from "../models/Customer";
import { getCustomers, updateCustomer } from "../services/CustomerService";
import { Text, Box, Button, Flex, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useBreakpointValue } from "@chakra-ui/react";
import MainMenu from "./MainMenu";
import DatePicker from "./DatePicker";
import { ChevronDownIcon } from "@chakra-ui/icons";
import MonthYearPicker from "./MonthYearPicker";
import { getYMD } from "../utils/DateUtils";
import CustomerEditForm from "./CustomerEditForm";
import { AxiosError } from "axios";
import CustomerPDFDownloaBox from "./CustomerPDFDownloadBox";

const CustomerListPage: React.FC = () => {
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [selectedMode, setSelectedMode] = useState("daily"); // "daily" is the default mode
    const [searchParams] = useSearchParams();
    const [page, setPage] = useState(1)
    const [selectedDateFrom, setSelectedDateFrom] = useState(new Date())
    const [selectedDateTo, setSelectedDateTo] = useState(new Date())
    const [isLoadingData, setLoadingData] = useState(true)
    const [isEditDialogOpen, setEditDialogOpen] = useState(false)
    const [customerForEdit, setCustomerForEdit] = useState<Customer>()

    const [customerToDownload, setCustomerToDownload] = useState<Customer>()

    const isMobile = useBreakpointValue({ base: true, md: false });


    useEffect(() => {
        setPage(1)
    }, [selectedDateFrom])

    useEffect(() => {
        fetchCustomers();
    }, [page, selectedDateFrom]);

    useEffect(() => {
        const modeParam = searchParams.get("mode");
        setSelectedMode(modeParam || "daily"); // If 'mode' parameter not present, default to "daily"
    }, [searchParams]);



    async function fetchCustomers() {

        setLoadingData(true)

        try {
            const customersData = await getCustomers({
                dateFrom: getYMD(selectedDateFrom),
                dateTo: getYMD(selectedDateTo),
                page
            });
            setCustomers(customersData);
        } catch (error) {
            console.error("Failed to fetch customers:", error);
        } finally {
            setLoadingData(false)
        }
    }

    function handleModeChange(mode: string) {
        setSelectedMode(mode);
        searchParams.set("mode", mode);
        window.history.replaceState({}, "", `${window.location.pathname}?${searchParams.toString()}`);
    }

    function handleDateChange(date: Date) {
        setSelectedDateFrom(date)
        setSelectedDateTo(date)
    }

    function handleDateChangeMonthYear(month: number, year: number) {
        setSelectedDateFrom(new Date(year, month, 1))
        setSelectedDateTo(new Date(year, month, 31))
    }

    function handleNextPage() {
        setPage(page + 1)
    }

    function handlePreviousPAge() {
        setPage(page < 2 ? 1 : page - 1)
    }

    function handleCustomerEdit(customer: Customer) {
        setCustomerForEdit(customer)
        setEditDialogOpen(true)
    }

    function handleCustomerEditSubmit(customer: Customer) {
        updateCustomer(customer).then(_ => {
            alert('updated')
            fetchCustomers()
        }).catch(err => {
            alert('update failed: ' + err.message)
        }).finally(() => {
        })

    }

    function handleCustomerDownload(customer: Customer) {
        setCustomerToDownload(customer)
    }

    function handleEditDialogClose() {
        setEditDialogOpen(false)
    }

    function handlePDFDownloadDialogClose() {
        setCustomerToDownload(undefined)
    }


    return (
        <Box padding={0} backgroundColor={"#303c6c"}>
            <Flex justifyContent="space-between" alignItems="center" mb={4} mt={6} ml={6}>
                <MainMenu current="data" />
            </Flex>
            <Box backgroundColor={"white"} padding={2} borderRadius={10}>
                <Flex mb={5} justifyContent={"center"} alignItems={"center"} mt={5} flexDirection={isMobile ? "column" : "row"} gap={4}>
                    <Menu>
                        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} width={150}>
                            {selectedMode.toUpperCase()}
                        </MenuButton>
                        <MenuList>
                            <MenuItem onClick={() => handleModeChange("daily")}>DAILY</MenuItem>
                            <MenuItem onClick={() => handleModeChange("monthly")}>MONTHLY</MenuItem>
                        </MenuList>
                    </Menu>
                    <Box ml={isMobile == false ? 4 : 0}>
                        {selectedMode == 'daily' ? (
                            <DatePicker onDateChange={handleDateChange} isDisabled={isLoadingData} />
                        ) : (<MonthYearPicker onDateChange={handleDateChangeMonthYear} />)}

                    </Box>
                </Flex>
                {customers.length > 0 ? (
                    <CustomerList customers={customers} onEditClick={handleCustomerEdit} onDownloadClick={handleCustomerDownload} />
                ) : (
                    <Flex my={10} justifyContent={"center"}>
                        <Text color={"gray"}>No Data</Text>
                    </Flex>
                )}

            </Box>
            <Box mt={4} background={"white"}></Box>
            <Flex w="100%" justifyContent="center" gap={10}>
                <Button isDisabled={isLoadingData} minW="100px" onClick={handlePreviousPAge}>&lt;</Button>
                <Text color={"white"} fontSize={25}>{page}</Text>
                <Button isDisabled={isLoadingData} minW={100} onClick={handleNextPage}>&gt;</Button>
            </Flex>


            {/*  */}

            <Modal isOpen={isEditDialogOpen}
                onClose={handleEditDialogClose}
                isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{""}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {customerForEdit ? (
                            <CustomerEditForm customer={customerForEdit} onSubmit={handleCustomerEditSubmit} />
                        ) : null}
                    </ModalBody>
                </ModalContent>
            </Modal>

            {
                customerToDownload ? (
                    <Modal isOpen={customerToDownload != undefined}
                        onClose={handlePDFDownloadDialogClose}
                        isCentered>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>{""}</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                {customerToDownload ? (
                                    <CustomerPDFDownloaBox customer={customerToDownload} />
                                ) : null}
                            </ModalBody>
                        </ModalContent>
                    </Modal>
                ) : null
            }




            {/*  */}
        </Box>
    );
};

export default CustomerListPage;
