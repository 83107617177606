import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import LoginForm from "./components/LoginForm";
import Dashboard from "./components/Dashboard";
import Home from "./components/Home";
import CustomerListPage from "./components/CustomerListPage";
import { getUserSelf } from "./services/UserService";
import { Box } from "@chakra-ui/react";
import CurrentEvent from "./pages/CurrentEvent";
import Analysis from "./pages/Analysis";



const App: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate()

  useEffect(() => {

    // getUserSelf()
    //   .then((user) => {
    //     setIsLoggedIn(user !== undefined);
    //   })
    //   .catch((error) => {
    //     //console.error("Error while fetching user data:", error);
    //   })
    //   .finally(() => {
    //     setIsLoading(false);
    //   });

  }, []);


  // if (isLoading) {
  //   return <div>Loading...</div>; // You can replace this with a loading spinner or component
  // }

  function handleLoggedIn() {
    setIsLoggedIn(true)
    navigate('/dashboard')
  }

  if (!isLoggedIn && (location.pathname !== "/login") && location.pathname !== '/' && location.pathname !== '/tools/analysis' && location.pathname !== '/events' && location.pathname !== '/events/current') {
    return <Navigate to="/login" />;
  }

  if (location.pathname == '/' || location.pathname == '/events') {
    return <Navigate to="/events/current" />;

  }

  return (
    <Box height="100vh">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/data" element={<CustomerListPage />} />
        <Route path="/events/current" element={<CurrentEvent />} />
        <Route path="/login" element={<LoginForm onLoggedIn={handleLoggedIn} />} />
        <Route path="/tools/analysis" element={<Analysis />} />
      </Routes>
    </Box>
  );
};

export default App;
