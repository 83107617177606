import React, { useState } from 'react';
import { Box, Button, Flex, FormControl, FormLabel, Input, Select } from '@chakra-ui/react';
import { Customer } from '../models/Customer';


interface CustomerEditFormProps {
    customer: Customer;
    onSubmit: (updatedCustomer: Customer) => void;
}

const CustomerEditForm: React.FC<CustomerEditFormProps> = ({ customer, onSubmit }) => {
    const [updatedCustomer, setUpdatedCustomer] = useState<Customer>(customer);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onSubmit(updatedCustomer);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        setUpdatedCustomer({ ...updatedCustomer, [name]: value });
    };

    return (
        <Box p={4} maxWidth="400px" margin="0 auto">
            <form onSubmit={handleSubmit}>
                <FormControl>
                    <FormLabel>Name</FormLabel>
                    <Input type="text" value={updatedCustomer.name}
                        disabled={true}
                    // readOnly 
                    />
                </FormControl>
                <FormControl mt={4}>
                    <FormLabel>Birthdate</FormLabel>
                    <Input
                        disabled={true}
                        type="date"
                        name="birthdate"
                        value={updatedCustomer.birthdate.toString()}
                        onChange={handleChange}
                        required
                    />
                </FormControl>
                <Flex gap={"4"}>
                    <FormControl >
                        <FormLabel>Atd L</FormLabel>
                        <Input
                            type="number"
                            name="atdL"
                            value={updatedCustomer.atdL}
                            onChange={handleChange}
                            required
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Atd R</FormLabel>
                        <Input
                            type="number"
                            name="atdR"
                            value={updatedCustomer.atdR}
                            onChange={handleChange}
                            required
                        />
                    </FormControl>
                </Flex>
                <FormControl mt={4}>
                    <FormLabel>Report</FormLabel>
                    <Select disabled={true} name="report" value={updatedCustomer.report.name} onChange={handleChange} required>
                        <option value="todo">{customer.report.name}</option>
                    </Select>
                </FormControl>
                <Flex justifyContent={"flex-end"}>
                    <Button mt={4} colorScheme="teal" type="submit">
                        Save Changes
                    </Button>
                </Flex>
            </form>
        </Box>
    );
};

export default CustomerEditForm;
