import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { Button, ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";

const rootElement = document.getElementById("root");



if (rootElement) {
    ReactDOM.createRoot(rootElement).render(
        <ChakraProvider theme={theme}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </ChakraProvider>
    );
} else {
    throw new Error("Root element with ID 'root' not found.");
}