// CustomerService.ts

import axios, { AxiosError } from "axios";
import { Customer } from "../models/Customer";
import { getDummyReport } from "./ReportService";
import config from "../config";
import { CountByDate } from "../models/CountByDate";

const BASE_URL = config.apiUrl; // Replace with your API base URL


export interface GetCustomersOptions {
    status?: 0 | 1 | undefined
    dateFrom?: string
    dateTo?: string
    sortBy?: 'id' | 'date' | undefined
    sortOrder?: 'asc' | 'desc' | undefined
    pageSize?: number
    page?: number
}

export async function getCustomers({ status, dateFrom, dateTo, sortBy, sortOrder, pageSize = 10, page = 1 }: GetCustomersOptions): Promise<Customer[]> {
    try {

        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth(); // Adding 1 because getMonth() returns zero-based index
        const dateFromDef = `${year}-${month.toString().padStart(2, '0')}-01`;
        const dateToDef = `${year}-${(month + 1).toString().padStart(2, '0')}-31`;

        let url = `${BASE_URL}/customers?page=${page}&page_size=${pageSize}`
        if (status != undefined) url += '&status=' + status
        if (dateFrom) url += '&date_from=' + dateFrom
        else url += '&date_from=' + dateFromDef
        if (dateTo) url += '&date_to=' + dateTo
        else url += '&date_to=' + dateToDef
        if (sortBy) url += '&sort_by=' + sortBy
        if (sortOrder) url += '&sort_order=' + sortOrder

        // console.log(url);

        const response = await axios.get<Customer[]>(url, {
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        // Handle error appropriately (e.g., logging, error handling)
        throw new Error("Failed to fetch customers");
    }
}

export interface GetCustomersCountOptions {
    dateFrom?: Date
    dateTo?: Date
}

export async function getCustomersCount({ dateFrom, dateTo }: GetCustomersCountOptions) {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based index
    const dateFromDef = `${year}-${(month - 5).toString().padStart(2, '0')}-01`;
    const dateToDef = `${year}-${(month).toString().padStart(2, '0')}-31`;


    let url = `${BASE_URL}/customers/count?`
    if (dateFrom) url += '&date_from=' + dateFrom
    else url += '&date_from=' + dateFromDef
    if (dateTo) url += '&date_to=' + dateTo
    else url += '&date_to=' + dateToDef

    try {
        const response = await axios.get<CountByDate[]>(url, {
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        // Handle error appropriately (e.g., logging, error handling)
        throw new Error("Failed to fetch customers");
    }
}

export async function postCustomerPDF(id: number) {
    let url = `${BASE_URL}/customers/${id}/pdf`
    try {
        const response = await axios.post(url, {}, {
            withCredentials: true
        })
        return response.data
    } catch (err) {
        const message = err instanceof AxiosError ? (err.response?.data.error || err.response?.data) : 'unknown error'
        throw new Error(message)
    }

}

export async function getCustomerPDF(customerId: number, taskId: string) {
    let url = `${BASE_URL}/customers/${customerId}/pdf/${taskId}`
    try {
        const response = await axios.get(url, {
            withCredentials: true
        })
        return response.data
    } catch (err) {
        const message = err instanceof AxiosError ? (err.response?.data.error || err.response?.data) : 'unknown error'
        throw new Error(message)
    }
}

export async function updateCustomer(customer: Customer) {

    const data = {
        atdL: customer.atdL,
        atdR: customer.atdR
    }

    try {
        const response = await axios.patch(`${BASE_URL}/customers/${customer.id}`, data, {
            withCredentials: true
        })
        return response.data
    } catch (err) {
        const message = err instanceof AxiosError ? err.response?.data.error : 'unknown error'
        throw new Error(message)
    }



}




// export async function getCustomers(): Promise<Customer[]> {
//     // Simulate an asynchronous delay to mimic API response time
//     await delay(1000);

//     // Return the dummy customers
//     return dummyCustomers;
// }

// function delay(ms: number) {
//     return new Promise((resolve) => setTimeout(resolve, ms));
// }


// const dummyCustomers: Customer[] = [
//     { id: 1, name: "John Doe", email: "john@example.com", status: 1, report: getDummyReport(), date: new Date(), atdL: 0, atdR: 0, birthdate: new Date() },
//     { id: 2, name: "Jane Smith", email: "jane@example.com", status: 0, report: getDummyReport(), date: new Date(), atdL: 0, atdR: 0, birthdate: new Date() },
//     { id: 3, name: "Bob Johnson", email: "bob@example.com", status: 1, report: getDummyReport(), date: new Date(), atdL: 0, atdR: 0, birthdate: new Date() },
// ];  
