import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, Center, Flex, Heading, NumberInput, NumberInputField, Text, Tooltip } from "@chakra-ui/react";
import React, { useState } from "react";
import { InfoOutlineIcon } from '@chakra-ui/icons';

interface ReportDownloadFormProps {

}

const ReportDownloadForm: React.FC<ReportDownloadFormProps> = ({ }) => {

    const [showAlert, setShowAlert] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState<number>(0)
    const [registeredNumber, setRegisteredNumber] = useState<number>(0)
    const [otp, setOtp] = useState(0)

    const [timeRemaining, setTimeRemaining] = useState(5);
    const [buttonText, setButtonText] = useState('cek')
    const [buttonDisabled, setButtonDisabled] = useState(false)

    //
    const [isOtpButtonVisible, setOtpButtonVisible] = useState(false)
    const [isOtpButtonDisabled, setOtpButtonDisabled] = useState(false)


    function isNumberRegitered(phone: number) {
        if (phone == 81222333155) return true
        return false
    }

    function handleDownload() {

        if (otp > 999) {
            // send request to server
        } else {
            if (isNumberRegitered(phoneNumber)) {
                setRegisteredNumber(phoneNumber)
                setOtpButtonVisible(true)
                setButtonText('Download')
            } else {
                setShowAlert(true)
            }
        }
    }

    function handlePhoneChange(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        setPhoneNumber(+event.target.value)
    }

    function handleOtpChange(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        setOtp(+event.target.value)
    }

    function handleOtpButtonClick() {
        setOtpButtonDisabled(true)
        setOtp(999)

        let timer = setInterval(() => {
            setTimeRemaining((prevTime) => prevTime - 1);
        }, 1000);

        setTimeout(() => {
            setOtpButtonDisabled(false)
            clearInterval(timer);
            setTimeRemaining(5);
        }, 5000);
    }



    return (
        <Flex padding={"10"} direction={"column"}>

            <Flex alignItems={"center"} mt={35} >
                <Heading textAlign={"center"} mr={2} size={"lg"}>Nomor Handphone</Heading>
                <Tooltip fontSize={30} label="nomor yang di-input saat pengambilan data. kode verifikasi akan dikirim via whatsapp">
                    <InfoOutlineIcon />
                </Tooltip>
            </Flex>
            <NumberInput mt={2} >
                <NumberInputField fontSize={"4xl"} textAlign={"center"} p={7} onChange={handlePhoneChange} disabled={registeredNumber > 0} />
            </NumberInput>

            {registeredNumber > 0 ? (
                <Box>
                    <Flex alignItems={"center"} mt={35} >
                        <Heading textAlign={"center"} mr={2} size={"lg"}>OTP</Heading>
                        <Tooltip fontSize={30} label="otp">
                            <InfoOutlineIcon />
                        </Tooltip>
                    </Flex>
                    <NumberInput mt={2} >
                        <NumberInputField fontSize={"4xl"} textAlign={"center"} p={7} maxLength={4} onChange={handleOtpChange} />
                    </NumberInput>
                </Box>
            ) : null}

            <Flex mt={4} flexDir={"column"}>
                <Button isDisabled={isOtpButtonDisabled} size={"lg"} bg={"whatsapp.500"} visibility={isOtpButtonVisible ? 'visible' : 'collapse'} onClick={handleOtpButtonClick} >{isOtpButtonDisabled ? `${Math.floor(timeRemaining / 60)}:${(timeRemaining % 60)
                    .toString()
                    .padStart(2, "0")}` : 'Kirim OTP ke Whatsapp'}</Button>
                <Button mt={4} size={"lg"} isDisabled={buttonDisabled} onClick={handleDownload}>{buttonDisabled ? ''
                    : buttonText}</Button>

            </Flex>
            <Alert status='info' mt={10} visibility={showAlert && registeredNumber <= 0 ? 'visible' : 'hidden'} >
                <AlertIcon />
                <AlertTitle>Nomor belum terdaftar</AlertTitle>
                <AlertDescription>segera hubungi kami untuk prrogram yang menarik!</AlertDescription>
            </Alert>
        </Flex>

    )
}

export default ReportDownloadForm
