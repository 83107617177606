import React from "react";
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box, Text, Flex, Button, Link } from "@chakra-ui/react";
import { Customer } from "../models/Customer";

interface CustomerListProps {
    customers: Customer[];
    onEditClick?: (customer: Customer) => void
    onDownloadClick?: (customer: Customer) => void
}



const CustomerList: React.FC<CustomerListProps> = ({ customers, onDownloadClick: onCustomerDownloadClick, onEditClick: onCustomerEditClick }) => {
    const handleEdit = (customer: Customer) => {
        if (onCustomerEditClick) onCustomerEditClick(customer)
    }

    const handleDownload = (customer: Customer) => {
        if (onCustomerDownloadClick) onCustomerDownloadClick(customer)

    }
    return (
        <Accordion allowToggle>
            {customers.map((customer) => (
                <AccordionItem key={customer.id}>
                    <h2>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                {customer.name}
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel>
                        <Text>{`birthdate: ${customer.birthdate.toString()}`}</Text>
                        {/* <Text>{`Email: ${customer.email}`}</Text> */}
                        {/* <Text>{`Phone: ${customer.phone}`}</Text> */}
                        <Text>{`report: ${customer.report.name}`}</Text>
                        <Text>{`atdL: ${customer.atdL} atdR: ${customer.atdR}`}</Text>
                        <Flex justifyContent="flex-end" mt={4} gap={2}>
                            <Button onClick={() => handleEdit(customer)}>Edit</Button>
                            <Button onClick={() => handleDownload(customer)}>Download PDF</Button>
                        </Flex>
                    </AccordionPanel>
                </AccordionItem>
            ))
            }
        </Accordion >
    );
};

export default CustomerList;
