import React from "react";
import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";

interface MainMenuProps {
    current: string;
}

const links = {

}

const MainMenu: React.FC<MainMenuProps> = ({ current }) => {
    return (
        <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                {current}
            </MenuButton>
            <MenuList>
                <MenuItem as={Link} to={"/"}>Home</MenuItem>
                <MenuItem as={Link} to={"/dashboard"}>Dashboard</MenuItem>
                <MenuItem as={Link} to={"/data"}>Data</MenuItem>

                <MenuItem> Logout </MenuItem>
            </MenuList>
        </Menu>
    );
};

export default MainMenu;
