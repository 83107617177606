import React, { useEffect, useRef } from "react";
import { Chart, registerables } from "chart.js";

interface BarChartProps {
    data: number[];
    labels: string[];
}

const BarChart: React.FC<BarChartProps> = ({ data, labels }) => {
    const chartRef = useRef<HTMLCanvasElement | null>(null);
    const chartInstance = useRef<Chart | null>(null);

    useEffect(() => {
        if (chartRef.current) {
            const ctx = chartRef.current.getContext("2d");
            if (ctx) {
                Chart.register(...registerables);

                if (chartInstance.current) {
                    // If a previous chart instance exists, destroy it
                    chartInstance.current.destroy();
                }

                chartInstance.current = new Chart(ctx, {
                    type: "bar",
                    data: {
                        labels,
                        datasets: [
                            {
                                data,
                                backgroundColor: "rgba(54, 162, 235, 0.6)",
                            },
                        ],
                    },
                    options: {
                        responsive: true,
                        scales: {
                            y: {
                                beginAtZero: true,
                            },
                        },
                        plugins: {
                            legend: {
                                display: false,
                            },
                        },
                    },
                });
            }
        }
    }, [data, labels]);

    return <canvas ref={chartRef}></canvas>;
};

export default BarChart;
