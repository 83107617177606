

const config = {
    apiUrl: process.env.REACT_APP_API_URL
}

if (!config.apiUrl) {
    throw new Error("config error")
}

export default config