import { ComponentStyleConfig, extendTheme } from "@chakra-ui/react";
import type { StyleFunctionProps } from "@chakra-ui/styled-system";


const Button: ComponentStyleConfig = {
    // 1. We can update the base styles
    baseStyle: {
    },
    // 2. We can add a new button size or extend existing
    sizes: {
        lg: {
            fontSize: '23px'
        }
    },
    // 3. We can add a new visual variant
    variants: {

        primary: {
            bg: 'brand.primary',
            color: 'white'
        }
    },
    // 6. We can overwrite defaultProps
    defaultProps: {
        variant: 'primary'
    },
}

// const components = {
//     Button

// };

const theme = extendTheme({
    colors: {
        brand: {
            100: "#303c6c",
            primary: '#FE7235'
        }
    },
    fontSizes: {
        xs: '10px'
    },
    styles: {
        global: {
            body: {
                bg: 'brand.100'
            }
        }
    },
    components: {
        Button
    }
})

export default theme